import React, { CSSProperties, useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import Select, { ActionMeta } from 'react-select';

export interface ICustomSelectProps extends StateManagerProps {
  style?: CSSProperties;
  required?: boolean;
  label?: string;
}

const CustomSelect = ( { required, defaultValue, onChange, className, label, ...props }: ICustomSelectProps ) => {
  const [ value, setValue ] = useState<any>( defaultValue );

  const style: any = {
    opacity: 0,
    pointerEvents: 'none',
    width: '0',
    height: '0',
    position: 'absolute',
    bottom: '0',
  };

  const change = ( newValue: any, actionMeta: ActionMeta<any> ) => {
    setValue( newValue );
    if ( onChange ) onChange( newValue, actionMeta );
  };

  const isEmpty = !(value?.value || (value ? value[ 0 ]?.value : null));

  return (
    <div className='position-relative w-100' style={ props.style }>
      { label &&
        <label
          style={ { position: 'absolute', top: -12, left: 10, zIndex: 2, backgroundColor: '#fff' } }>{ label }</label>
      }
      <input required={ required } style={ style } value={ isEmpty ? '' : '1' } readOnly/>
      <Select
        { ...props }
        className={ 'react-select ' + className }
        classNamePrefix={ 'react-select' }
        defaultValue={ value }
        onChange={ change }
      />
    </div>
  );
};

export default CustomSelect;
